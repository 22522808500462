<template>

      <div class="container">
        <div style="min-height: 400px" class="row ">

            <div class="col-md-2 col-sm-2 ">
             <sidebar/>
           </div>

          <div class="col-md-10 col-lg-10">
            <div class="form-container content">
              <form
                @submit.prevent="UpdateProfile"
                enctype="multipart/form-data"
              >
                <div class="form-group mb-2">
                  <label class="form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('name') }"
                    name="name"
                    v-model="form.name"
                    autocomplete="off"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group mb-2">
                  <label class="form-label">Mobile Number</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('mobile_no') }"
                    name="mobile_no"
                    v-model="form.mobile_no"
                    autocomplete="off"
                  />
                  <has-error :form="form" field="mobile_no"></has-error>
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">City</label>

                  <select name="city" v-model="form.city" class="form-control">
                    <option disabled>your city</option>
                    <option
                      v-for="(city, index) in cities"
                      :key="index"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <has-error :form="form" field="city"></has-error>
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('email') }"
                    name="email"
                    v-model="form.email"
                    autocomplete="off"
                  />
                  <has-error :form="form" field="email"></has-error>
                </div>
                <div class="form-group mb-2">
                  <label class="form-label">Address</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('address') }"
                    name="address"
                    v-model="form.address"
                    autocomplete="off"
                  />
                  <has-error :form="form" field="address"></has-error>
                </div>

                <div class="form-group mb-2 text-center">
                  <button
                    :disabled="form.busy"
                    class="btn btn-sm btn-primary"
                    type="submit"
                  >
                    <i class="fa fa-spinner fa-spin" v-if="form.busy"></i>
                    UPDATE
                  </button>
                </div>
              </form>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

</template>

<script>
import Vue from "vue";
import { Form, HasError } from "vform";
import ObjectToFD from 'vue-object-to-formdata';
Vue.use(ObjectToFD);
import sidebar from "../../components/Sidebar.vue"

export default {
  created() {
    this.getCity();
    this.user();
  },

  data() {
    return {
      order_lists: {},
      loading: true,
      form: new Form({
        name: null,
        email: null,
        mobile_no: null,
        address: null,
        city: null,
        user_token:localStorage.getItem('user_token'),
      }),
      cities: null,
    };
  },

  methods: {
    UpdateProfile() {
      this.form
        .post("update/user/profile",this.$objectToFD(this.form))
        .then((resp) => {
          console.log(resp);
          if (resp.data.status == "SUCCESS") {
            this.$toast.success(resp.data.message)
          } else {
            this.$toast.error("something went wrong!")
          }
        })

    },

    getCity() {
      this.axios
        .get("get/city/list")
        .then((resp) => {
          this.cities = resp.data.cities;
        })
    },


    user() {
      this.axios
        .get("/userToCheck", {
             params:{
                 user_token:localStorage.getItem('user_token')
                }
             } )
        .then((resp) => {
          if (resp.data.status == "AUTHORIZED") {
            this.form.name = resp.data.user.name;
            this.form.mobile_no = resp.data.user.mobile_no;
            this.form.address = resp.data.user.address;
            this.form.city = resp.data.user.city_id;
            this.form.email = resp.data.user.email ? resp.data.user.email : 'add email';
          }
        })
    },


  },
  components:{
    sidebar,
    HasError
  }
};
</script>

<style scoped>

 .form-container{
   margin: 0% 20%;
   width: 60%;
   height: 420px;
   margin-bottom: 50px;
   margin-top: 26px;
   box-shadow:1px 1px 5px 3px #ddd;
 }

.content{
  background:#fff;
  padding: 20px 20px;
}
</style>